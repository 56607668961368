<template>
    <div>
        <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters" :buttonRemove="true">
            <div slot="content-buttons">
                <Button _key="btnOrderServiceCreate" title="Ordem de Serviço" classIcon="fas fa-plus-circle"
                    type="primary" size="small" :clicked="create" />
            </div>
        </ViewTemplateWithTable>
        <Modal title="Tem certeza que deseja excluir?" :width="480" v-show="showModal('removeOs')">
            <Alert type="warning">
                <span>
                    Atenção, ao excluir uma OS alguns processos serão executados, esta ação é
                    irreversível...
                </span>
            </Alert>
            <InputText title="Digite Remover para confirmar" :maxLength="7" :required="false" v-model="confirmRemove" />
            <Confirmation :isModal="false" :disabled="confirmRemove != 'Remover'" title="Você tem certeza?"
                type="danger" :confirmed="remove" />
        </Modal>
    </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "OsServiceListView",
    components: {
        ViewTemplateWithTable,
        Confirmation,
        Button,
        Modal,
        Alert,
        InputText
    },
    data() {
        return {
            id: "",
            confirmRemove: "",
            panel: {
                module: "Manutenção",
                title: "Ordem de Serviço",
                showFilter: true,
                showSearch: true,
                showButtons: true,
            },
            templateList: {
                urlGetApi: "/api/v1/maintenance/order-service/get-all",
                urlDeleteAllApi: "/api/v1/maintenance/order-service/delete",
                showChecks: false,
                headerTable: [
                    {
                        title: "Número/Data",
                        field: "numberAndDate",
                        type: "text",
                        iconSearch: true,
                    },
                    {
                        field: "supplier",
                        title: "Fornecedor",
                        type: "link",
                        routeName: "orderServiceUpdate",
                        styleBody: "max-width: 500px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                        iconSearch: true,
                    },
                    {
                        field: "customer",
                        fieldSecond: "identification",
                        title: "Cliente",
                        type: "link",
                        routeName: "orderServiceUpdate",
                        styleBody: "max-width: 500px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                        iconSearch: true,
                    },
                    {
                        title: "Valor Cliente",
                        field: "valueCustomer",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                        type: "currency",
                    },
                    {
                        title: "Valor Fornecedor",
                        field: "valueSupplier",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                        type: "currency",
                    },
                    {
                        type: "button",
                        typeButton: "default",
                        button: {
                            classIcon: "fa-solid fa-trash",
                            type: "danger",
                            size: "small",
                            eventName: "removeOs",
                        },
                    },
                ],
            },
            filters: [
                {
                    type: "date",
                    title: "Data Cadastro",
                    field: "RegisteredDate",
                    value: "",
                },
                {
                    type: "decimal",
                    title: "Valor",
                    field: "TotalGeneral",
                    valueStart: 0,
                    valueEnd: 0,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["deleteAllApi"]),
        create() {
            this.$router.push({
                name: "orderServiceCreate",
            });
            this.removeLoading(["btnOrderServiceCreate"]);
        },
        remove() {
            let params = {
                url: this.templateList.urlDeleteAllApi,
                selected: [this.id],
            };

            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.hideModal("removeOs");
                }
                this.removeLoading(["confirm"]);
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "removeOs") {
                    this.id = event.data.id;
                    this.confirmRemove = "";
                    this.openModal("removeOs");
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.rent-icon-close {
    color: red;
}
</style>